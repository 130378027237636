export const ROLES = {
    PARTICIPANT: 'participant',
    RESEARCHER: 'researcher',
};

export const ROLE_IDS = {
    PARTICIPANT: 0,
    RESEARCHER: 1,
};

export function getRoleById(roleId) {
    switch (roleId) {
        case ROLE_IDS.PARTICIPANT:
            return ROLES.PARTICIPANT;
        case ROLE_IDS.RESEARCHER:
            return ROLES.RESEARCHER;
        default:
            throw new Error(`Role by ID of ${roleId} not found!`);
    }
}

export function getIdOfRole(role) {
    switch (role) {
        case ROLES.PARTICIPANT:
            return ROLE_IDS.PARTICIPANT;
        case ROLES.RESEARCHER:
            return ROLE_IDS.RESEARCHER;
        default:
            throw new Error(`Role ID of ${role} not found!`);
    }
}

export function isParticipant(role) {
    return role === ROLES.PARTICIPANT;
}

export function isResearcher(role) {
    return role === ROLES.RESEARCHER;
}
