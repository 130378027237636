import camelcaseKeys from 'camelcase-keys';
import { get, isEqual, transform, unset } from 'lodash-es';
import snakecaseKeys from 'snakecase-keys';

function hasKey(object, key) {
    return Object.prototype.hasOwnProperty.call(object, key);
}

function isObject(item) {
    return item && typeof item === 'object' && !Array.isArray(item);
}

function mergeDeep(target, ...sources) {
    if (!sources.length) {
        return target;
    }
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach((key) => {
            if (isObject(source[key])) {
                if (!target[key]) {
                    Object.assign(target, { [key]: {} });
                }
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        });
    }

    return mergeDeep(target, ...sources);
}

function getValueOfKey(object, key, defaultValue) {
    return hasKey(object, key) ? object[key] : defaultValue;
}

/*
    Calculate difference of a base object with a changed object comes from base object.
    This function check object keys value, if the value is not an object, it'll check if original and new object value is equal,
    if not, new value in new object will be added to result, if not, it'll be omitted. If the key's value is object, it'll run it
    recursively.
 */
function difference(originalObject, newObject) {
    function changes(newObj, origObj) {
        return transform(newObj, (result, value, key) => {
            if (!isEqual(value, origObj[key])) {
                /*
                    NOTE: The isObject used here will return true if the value is truly an object,
                    so it'll return false if it's an array. So the function will break if lodash isObject used
                    as It'll return true for array.
                 */
                // eslint-disable-next-line no-param-reassign
                result[key] = isObject(value) && isObject(origObj[key]) ? changes(value, origObj[key]) : value;
            }
        });
    }
    return changes(newObject, originalObject);
}

function snakeCaseKeys(object, excludedKeys = []) {
    return snakecaseKeys(object, { deep: true, exclude: [...excludedKeys] });
}

function camelCaseKeys(object, excludedKeys = []) {
    return camelcaseKeys(object, { deep: true, exclude: [...excludedKeys] });
}

function deletePathOrArrayItem(object, path) {
    // we can't use `unset` here, it'll make those array items `null`
    if (path.endsWith(']')) {
        const index = +path.substring(path.lastIndexOf('[') + 1, path.length - 1);
        const parentPath = path.substring(0, path.lastIndexOf('['));
        const parent = get(object, parentPath);
        parent.splice(index, 1);
    } else {
        unset(object, path);
    }
}

export { hasKey, isObject, mergeDeep, getValueOfKey, difference, snakeCaseKeys, camelCaseKeys, deletePathOrArrayItem };
