export function getDisplayName(Component) {
    if (typeof Component === 'string') {
        return Component;
    }

    if (!Component) {
        return undefined;
    }
    return Component.displayName || Component.name || 'Component';
}

export function compose(...funcs) {
    return funcs.reduce(
        (a, b) =>
            (...args) =>
                a(b(...args)),
        (arg) => arg
    );
}
