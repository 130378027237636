import axios from 'axios';
import URLParse from 'url-parse';

import appUtils from '@edf-pkg/app-utils';

let baseUrl = '';
if (appUtils.env.isDev()) {
    baseUrl = process.env.GATSBY_SERVER_BASE_URL;
} else {
    // Check for window for Gatsby SSR build

    if (typeof window !== `undefined`) {
        const parsedURL = new URLParse(window.location);
        baseUrl = parsedURL.origin;
    }
}

const defaultOptions = {
    timeout: 60000,
};

export const getUrl = (relativePath) => {
    return `${baseUrl}${relativePath}`;
};

const client = {
    api: axios.create({
        ...defaultOptions,
        baseURL: `${baseUrl}/api`,
    }),
};

export default client;
