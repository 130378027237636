import numeral from 'numeral';

import 'numeral/locales';

export function capitalizeFirstChar(theString) {
    return theString.charAt(0).toUpperCase() + theString.slice(1);
}

function italianNumberToOrdinal(number) {
    return `${number}°`;
}

function germanNumberToOrdinal(number) {
    return `${number}.`;
}

const LANGUAGES_TO_CUSTOM_MAP = {
    'de-DE': germanNumberToOrdinal,
    'it-IT': italianNumberToOrdinal,
};

const DEFAULT_NUMERAL_LOCALE = 'en';
// If a new language added to the project, the cardinal number rule should be added here
const LANGUAGES_TO_NUMERAL_LOCALE_MAP = {
    base: DEFAULT_NUMERAL_LOCALE,
    'en-US': 'en',
    'fr-CA': 'fr-ca',
    'nl-NL': DEFAULT_NUMERAL_LOCALE,
    'es-US': DEFAULT_NUMERAL_LOCALE,
    'es-CO': DEFAULT_NUMERAL_LOCALE,
    'sl-SI': DEFAULT_NUMERAL_LOCALE,
    'zu-ZU': DEFAULT_NUMERAL_LOCALE,
    'ca-ES': DEFAULT_NUMERAL_LOCALE,
    'ur-PK': DEFAULT_NUMERAL_LOCALE,
    'fa-IR': DEFAULT_NUMERAL_LOCALE,
};

export function getInOrdinalFormat(number, locale) {
    if (locale in LANGUAGES_TO_CUSTOM_MAP) {
        return LANGUAGES_TO_CUSTOM_MAP[locale](number);
    }

    if (locale in LANGUAGES_TO_NUMERAL_LOCALE_MAP) {
        numeral.locale(LANGUAGES_TO_NUMERAL_LOCALE_MAP[locale]);
    } else {
        numeral.locale(DEFAULT_NUMERAL_LOCALE);
    }
    return numeral(number).format('0o');
}
